@use 'variables' as *;
@use 'mixins' as *;
@use 'fonts' as *;


// General styles

*,
::before,
::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    vertical-align: baseline;
    background-color: transparent;
    appearance: none;
    color: inherit;
    border-radius: 0;
    border: 0;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
    scrollbar-width: none;
    text-align: center;
    color: #fff;
}

body {
    scrollbar-width: none;
    font-family: $font-family-sans;
    background: linear-gradient(
        #E6007E,
        #AA005C,
        #2581C4,
        #009FE3,
        #2581C4,
        #AA005C,
        #E6007E
    );

    &::-webkit-scrollbar {
        display: none;
    }
}

img, svg {
    display: block;
    max-width: 100%;
    height: auto;
}

h1, h2, h3, h4 {
    position: relative;
}

h2, h3, h4 {
    font-weight: 400;

    strong {
        font-family: $font-family-serif;
        font-size: 1.16em;
    }
}

h1 {
    font-family: $font-family-serif;
    font-size: clamp(2rem, 7vw, 6.25rem);
}

h2 {
    font-size: clamp(1.5rem, 5vw, 3.125rem);
}

h3 {
    font-size: clamp(1.25rem, 5vw, 2.5rem);
    font-family: $font-family-serif;
}

h4 {
    font-size: clamp(1rem, 3vw, 1.5rem);
}

section {
    padding: $block-spacing;

    @include breakpoint-min($breakpoint-tablet) {
        padding-inline: 6rem;
    }
}


// Loader stuff

.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
    transition: all .3s ease;
    background-color: #E6007E;
    z-index: 99;

    img {
        width: 4rem;
    }

    .is-loaded & {
        opacity: 0;
        pointer-events: none;
    }
}


// Header

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: clamp(1.5rem, 4vw, 3rem);
    z-index: 9;
}

.header-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint-min($breakpoint-tablet) {
        align-items: flex-start;
    }
}

.header-logo {
    display: block;
    width: 10rem;
    flex: 0 0 auto;
}

.header-house {
    display: block;
    width: 1rem;
    flex: 0 0 auto;
    transition: all .2s ease;
    transform-origin: center bottom;

    &:hover {
        transform: scale(1.15);
    }
}

.header-progress {
    display: none;

    @include breakpoint-min($breakpoint-tablet) {
        display: block;
        width: .125rem;
        height: calc(100vh - clamp(1.5rem, 4vw, 3rem) * 2 - 2.5rem);
        position: absolute;
        right: .4375rem;
        top: 2.5rem;
        background-color: rgba(#000, .2);
    }
}

.header-progress-indicator {
    position: relative;
    width: .375rem;
    left: -.125rem;
    background-color: #fff;
    border-radius: 1rem;
}


// Section 1 scroll link

.scroll-link {
    position: absolute;
    bottom: clamp(1.5rem, 4vw, 3rem);
    left: calc(50% - 1.25rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: inherit;

    svg {
        display: block;
        width: 2.5rem;
        margin-top: .5rem;
    }
}

.scroll-link-wheel {
    animation: scroll-link-wheel-bounce 1s ease infinite;
}

@keyframes scroll-link-wheel-bounce {
    0% { transform: none }
    50% { transform: translateY(3%) }
    100% { transform: none }
}


// Sections

.full-height-section {
    display: grid;
    place-items: center;
    position: relative;
    height: 100vh;
}


// Media

.section-media-primary,
.section-media-secondary {
    position: absolute;
    width: calc(100% - #{$block-spacing} * 2);
    border-radius: 1.25rem;
    overflow: hidden;
    max-width: 25rem;

    @include breakpoint-min($breakpoint-desktop-small) {
        max-width: none;
    }
}

.section-media-secondary {
    display: none;

    @include breakpoint-min($breakpoint-desktop-small) {
        display: block;
    }
}

.section-video {
    position: absolute;
    top: 50%;
    left: 50%;
    aspect-ratio: 16 / 9;
    opacity: 0;
    transition: opacity .2s ease;

    &.visible {
        opacity: 1;
    }

    iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-50%, -50%);
    }
}

.section-video-full-height {
    height: 100%;
    width: auto;
}

.section-video-full-width {
    height: auto;
    width: 100%;
}


// Individual sections

.section-1-image-1 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 25%;
        left: 14%;
        top: -4.4vw;
    }
}

.section-1-image-2 {
    opacity: .4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 24%;
        left: 57%;
        bottom: 4.3rem;
    }
}

.section-2-video-1 {
    aspect-ratio: 16 / 9;
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        left: 11%;
        top: 43%;
        width: 28%;
    }
}

.section-2-image-1 {
    opacity: .4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 29%;
        left: 62.5%;
    }
}

.section-3-image-1 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 34%;
        left: -2.5%;
        top: 40%;
    }
}

.section-3-video-1 {
    opacity: .4;
    aspect-ratio: 16 / 10;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 33%;
        left: 53%;
    }

    .section-video {
        aspect-ratio: 16 / 8;
    }
}

.section-4-video-1 {
    opacity: .6;
    aspect-ratio: 16 / 9;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 39%;
        max-width: 33.5rem;
    }
}

.section-5-video-1 {
    opacity: .4;
    aspect-ratio: 16 / 9;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 28%;
        left: 13%;
        top: 43%;
    }
}

.section-5-image-1 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 47%;
        left: 55.4%;
        top: 10%;
    }
}

.section-6-video-1 {
    opacity: .4;
    aspect-ratio: 16 / 9;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 40.4%;
        max-width: 34.5rem;
    }
}

.section-7-image-1 {
    opacity: .4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 43%;
        left: -2.4%;
        top: 20%;
    }
}

.section-7-video-1 {
    opacity: .6;
    aspect-ratio: 16 / 9;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 37%;
        left: 64.5%;
        top: 43.5%;
    }
}

.section-8-image-1 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 32%;
        left: 22%;
        top: 23%;
    }
}

.section-9 {
    h2 {
        font-size: clamp(3rem, 12vw, 10rem);
        font-family: $font-family-serif;
    }
}

.section-9-image-1 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 37.5%;
        left: 7%;
        top: 6%;
    }
}

.section-9-image-2 {
    opacity: .4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 21.5%;
        left: 80%;
        top: -3%;
    }
}

.section-9-video-1 {
    opacity: .6;
    aspect-ratio: 16 / 9;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 36%;
        left: 50%;
        top: 44%;
    }
}

.section-10-video-1 {
    opacity: .6;
    aspect-ratio: 16 / 9;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 35%;
        max-width: 30rem;
        margin-right: 10rem;
    }
}

.section-11-image-1 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 36.6%;
        left: 9.5%;
        top: 18%;
    }
}

.section-11-image-2 {
    opacity: .4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 20%;
        left: 71%;
        top: 36%;
    }
}

.section-12-video-1 {
    opacity: .6;
    aspect-ratio: 16 / 8.4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 37.6%;
        max-width: 32.125rem;
    }
}

.section-13-image-1 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 27.7%;
        left: -1%;
        top: 40%;
    }
}

.section-13-image-2 {
    opacity: .4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 19.5%;
        left: 37.5%;
        top: 10%;
    }
}

.section-13-image-3 {
    opacity: .6;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 41%;
        left: 61%;
        top: 34%;
    }
}


// Payoff

.payoff {
    width: calc(100% - 4rem);
    max-width: 24.5rem;
}

.payoff-logo {
    display: block;
    width: 100%;
    margin-bottom: clamp(1.5rem, 4vw, 4rem);
}


// Outro

.outro {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include breakpoint-min($breakpoint-desktop-small) {
        padding-top: 33vh;
        padding-bottom: 6.3rem;
    }
}

.outro-video-1 {
    opacity: .4;
    aspect-ratio: 16 / 9;

    @include breakpoint-max($breakpoint-desktop-small) {
        top: -5%;
    }

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 48%;
        left: -2.4%;
        top: 13%;
    }
}

.outro-video-2 {
    opacity: .6;
    aspect-ratio: 16 / 8.4;

    @include breakpoint-min($breakpoint-desktop-small) {
        width: 31.6%;
        left: 59%;
        top: 5%;
    }
}

.outro-subtitle {
    max-width: 18.75rem;
    margin: clamp(1rem, 4vw, 2.3rem) 0 clamp(5rem, 10rem, 11.7rem);
}

.outro-case {
    display: block;
    text-decoration: none;
}

.outro-cases {
    @include grid-layout(1);
    max-width: 25rem;

    @include breakpoint-min($breakpoint-desktop-small) {
        @include grid-layout(3, 1.25rem);
        max-width: 70rem;
    }
}

.outro-case {
    &:hover {
        .outro-case-play {
            transform: scale(1.1);
        }
    }
}

.outro-case-title {
    margin-bottom: clamp(1rem, 3vw, 1.25rem);
}

.outro-case-thumbnail {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 12rem;
        height: 12rem;
        position: absolute;
        left: calc(50% - 6rem);
        top: calc(50% - 6rem);
        z-index: 2;
        background: radial-gradient(rgba(#000, .4) 0%, rgba(#000, 0) 70%);
        border-radius: 50%;
    }
}

.outro-case-image {
    border-radius: .625rem;
}

.outro-case-play {
    position: absolute;
    width: 2.6rem;
    left: calc(50% - 1.3rem);
    top: calc(50% - 1.3rem);
    z-index: 3;
    transition: transform .2s ease;
}