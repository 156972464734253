@mixin breakpoint-min($breakpoint) {
    $width: if(unitless($breakpoint), $breakpoint + 'em', $breakpoint);

    @media(min-width: #{$width}) {
        @content;
    }
}

@mixin breakpoint-max($breakpoint) {
    $breakpoint: $breakpoint - .01;
    $width: if(unitless($breakpoint), $breakpoint + 'em', $breakpoint);

    @media(max-width: #{$width}) {
        @content;
    }
}

@mixin mq($from: null, $until: null) {
    @if ($from == null and $until == null) {
        @error 'At least one breakpoint must be provided';
    }

    @if (
        $from != null and (unitless($from) or unit($from) != 'em')
        or
        $until != null and (unitless($until) or unit($until) != 'em')
    ) {
        @error 'Breakpoints must always have "em" as a unit';
    }

    $rule: '';

    @if $from != null {
        $rule: '(min-width: #{$from})';
    }

    @if $from != null and $until != null {
        $rule: $rule + ' and '
    }

    @if $until != null {
        $rule: $rule + '(max-width: #{$until - .01})';
    }

    @media #{$rule} {
        @content;
    }
}

@mixin grid-layout($column-count, $column-gap: 2.4rem, $row-gap: null) {
    $row-gap: if($row-gap == null, $column-gap, $row-gap);

    display: grid;
    grid-template-columns: repeat($column-count, 1fr);
    grid-gap: $row-gap $column-gap;
    gap: $row-gap $column-gap;
}

@mixin grid-column-start($number) {
    grid-column-start: $number;
}

@mixin grid-row-start($number) {
    grid-row-start: $number;
}

@mixin grid-column-span($count) {
    grid-column-end: span $count;
}

@mixin grid-row-span($count) {
    grid-row-end: span $count;
}

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}