*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("inter-v12-latin-regular.cb07b618.woff2") format("woff2"), url("inter-v12-latin-regular.5f62869a.woff") format("woff");
}

@font-face {
  font-family: Petrona;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("petrona-v28-latin-700.d4677d75.woff2") format("woff2"), url("petrona-v28-latin-700.efe98a3b.woff") format("woff");
}

*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  appearance: none;
  color: inherit;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: none;
  text-align: center;
  color: #fff;
}

body {
  scrollbar-width: none;
  background: linear-gradient(#e6007e, #aa005c, #2581c4, #009fe3, #2581c4, #aa005c, #e6007e);
  font-family: Inter, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

img, svg {
  max-width: 100%;
  height: auto;
  display: block;
}

h1, h2, h3, h4 {
  position: relative;
}

h2, h3, h4 {
  font-weight: 400;
}

h2 strong, h3 strong, h4 strong {
  font-family: Petrona, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1.16em;
}

h1 {
  font-family: Petrona, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: clamp(2rem, 7vw, 6.25rem);
}

h2 {
  font-size: clamp(1.5rem, 5vw, 3.125rem);
}

h3 {
  font-family: Petrona, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: clamp(1.25rem, 5vw, 2.5rem);
}

h4 {
  font-size: clamp(1rem, 3vw, 1.5rem);
}

section {
  padding: clamp(1.5rem, 4vw, 3rem);
}

@media (min-width: 48em) {
  section {
    padding-inline: 6rem;
  }
}

.loader {
  width: 100%;
  height: 100vh;
  z-index: 99;
  background-color: #e6007e;
  place-items: center;
  transition: all .3s;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
}

.loader img {
  width: 4rem;
}

.is-loaded .loader {
  opacity: 0;
  pointer-events: none;
}

.header {
  width: 100%;
  z-index: 9;
  padding: clamp(1.5rem, 4vw, 3rem);
  position: fixed;
  top: 0;
  left: 0;
}

.header-inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 48em) {
  .header-inner {
    align-items: flex-start;
  }
}

.header-logo {
  width: 10rem;
  flex: none;
  display: block;
}

.header-house {
  width: 1rem;
  transform-origin: bottom;
  flex: none;
  transition: all .2s;
  display: block;
}

.header-house:hover {
  transform: scale(1.15);
}

.header-progress {
  display: none;
}

@media (min-width: 48em) {
  .header-progress {
    width: .125rem;
    height: calc(100vh + -2 * clamp(1.5rem, 4vw, 3rem) - 2.5rem);
    background-color: #0003;
    display: block;
    position: absolute;
    top: 2.5rem;
    right: .4375rem;
  }
}

.header-progress-indicator {
  width: .375rem;
  background-color: #fff;
  border-radius: 1rem;
  position: relative;
  left: -.125rem;
}

.scroll-link {
  text-align: center;
  color: inherit;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: absolute;
  bottom: clamp(1.5rem, 4vw, 3rem);
  left: calc(50% - 1.25rem);
}

.scroll-link svg {
  width: 2.5rem;
  margin-top: .5rem;
  display: block;
}

.scroll-link-wheel {
  animation: 1s infinite scroll-link-wheel-bounce;
}

@keyframes scroll-link-wheel-bounce {
  0% {
    transform: none;
  }

  50% {
    transform: translateY(3%);
  }

  100% {
    transform: none;
  }
}

.full-height-section {
  height: 100vh;
  place-items: center;
  display: grid;
  position: relative;
}

.section-media-primary, .section-media-secondary {
  width: calc(100% + -2 * clamp(1.5rem, 4vw, 3rem));
  max-width: 25rem;
  border-radius: 1.25rem;
  position: absolute;
  overflow: hidden;
}

@media (min-width: 60em) {
  .section-media-primary, .section-media-secondary {
    max-width: none;
  }
}

.section-media-secondary {
  display: none;
}

@media (min-width: 60em) {
  .section-media-secondary {
    display: block;
  }
}

.section-video {
  aspect-ratio: 16 / 9;
  opacity: 0;
  transition: opacity .2s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.section-video.visible {
  opacity: 1;
}

.section-video iframe {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.section-video-full-height {
  height: 100%;
  width: auto;
}

.section-video-full-width {
  height: auto;
  width: 100%;
}

.section-1-image-1 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-1-image-1 {
    width: 25%;
    top: -4.4vw;
    left: 14%;
  }
}

.section-1-image-2 {
  opacity: .4;
}

@media (min-width: 60em) {
  .section-1-image-2 {
    width: 24%;
    bottom: 4.3rem;
    left: 57%;
  }
}

.section-2-video-1 {
  aspect-ratio: 16 / 9;
  opacity: .6;
}

@media (min-width: 60em) {
  .section-2-video-1 {
    width: 28%;
    top: 43%;
    left: 11%;
  }
}

.section-2-image-1 {
  opacity: .4;
}

@media (min-width: 60em) {
  .section-2-image-1 {
    width: 29%;
    left: 62.5%;
  }
}

.section-3-image-1 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-3-image-1 {
    width: 34%;
    top: 40%;
    left: -2.5%;
  }
}

.section-3-video-1 {
  opacity: .4;
  aspect-ratio: 16 / 10;
}

@media (min-width: 60em) {
  .section-3-video-1 {
    width: 33%;
    left: 53%;
  }
}

.section-3-video-1 .section-video {
  aspect-ratio: 16 / 8;
}

.section-4-video-1 {
  opacity: .6;
  aspect-ratio: 16 / 9;
}

@media (min-width: 60em) {
  .section-4-video-1 {
    width: 39%;
    max-width: 33.5rem;
  }
}

.section-5-video-1 {
  opacity: .4;
  aspect-ratio: 16 / 9;
}

@media (min-width: 60em) {
  .section-5-video-1 {
    width: 28%;
    top: 43%;
    left: 13%;
  }
}

.section-5-image-1 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-5-image-1 {
    width: 47%;
    top: 10%;
    left: 55.4%;
  }
}

.section-6-video-1 {
  opacity: .4;
  aspect-ratio: 16 / 9;
}

@media (min-width: 60em) {
  .section-6-video-1 {
    width: 40.4%;
    max-width: 34.5rem;
  }
}

.section-7-image-1 {
  opacity: .4;
}

@media (min-width: 60em) {
  .section-7-image-1 {
    width: 43%;
    top: 20%;
    left: -2.4%;
  }
}

.section-7-video-1 {
  opacity: .6;
  aspect-ratio: 16 / 9;
}

@media (min-width: 60em) {
  .section-7-video-1 {
    width: 37%;
    top: 43.5%;
    left: 64.5%;
  }
}

.section-8-image-1 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-8-image-1 {
    width: 32%;
    top: 23%;
    left: 22%;
  }
}

.section-9 h2 {
  font-family: Petrona, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: clamp(3rem, 12vw, 10rem);
}

.section-9-image-1 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-9-image-1 {
    width: 37.5%;
    top: 6%;
    left: 7%;
  }
}

.section-9-image-2 {
  opacity: .4;
}

@media (min-width: 60em) {
  .section-9-image-2 {
    width: 21.5%;
    top: -3%;
    left: 80%;
  }
}

.section-9-video-1 {
  opacity: .6;
  aspect-ratio: 16 / 9;
}

@media (min-width: 60em) {
  .section-9-video-1 {
    width: 36%;
    top: 44%;
    left: 50%;
  }
}

.section-10-video-1 {
  opacity: .6;
  aspect-ratio: 16 / 9;
}

@media (min-width: 60em) {
  .section-10-video-1 {
    width: 35%;
    max-width: 30rem;
    margin-right: 10rem;
  }
}

.section-11-image-1 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-11-image-1 {
    width: 36.6%;
    top: 18%;
    left: 9.5%;
  }
}

.section-11-image-2 {
  opacity: .4;
}

@media (min-width: 60em) {
  .section-11-image-2 {
    width: 20%;
    top: 36%;
    left: 71%;
  }
}

.section-12-video-1 {
  opacity: .6;
  aspect-ratio: 16 / 8.4;
}

@media (min-width: 60em) {
  .section-12-video-1 {
    width: 37.6%;
    max-width: 32.125rem;
  }
}

.section-13-image-1 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-13-image-1 {
    width: 27.7%;
    top: 40%;
    left: -1%;
  }
}

.section-13-image-2 {
  opacity: .4;
}

@media (min-width: 60em) {
  .section-13-image-2 {
    width: 19.5%;
    top: 10%;
    left: 37.5%;
  }
}

.section-13-image-3 {
  opacity: .6;
}

@media (min-width: 60em) {
  .section-13-image-3 {
    width: 41%;
    top: 34%;
    left: 61%;
  }
}

.payoff {
  width: calc(100% - 4rem);
  max-width: 24.5rem;
}

.payoff-logo {
  width: 100%;
  margin-bottom: clamp(1.5rem, 4vw, 4rem);
  display: block;
}

.outro {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 60em) {
  .outro {
    padding-top: 33vh;
    padding-bottom: 6.3rem;
  }
}

.outro-video-1 {
  opacity: .4;
  aspect-ratio: 16 / 9;
}

@media (max-width: 59.99em) {
  .outro-video-1 {
    top: -5%;
  }
}

@media (min-width: 60em) {
  .outro-video-1 {
    width: 48%;
    top: 13%;
    left: -2.4%;
  }
}

.outro-video-2 {
  opacity: .6;
  aspect-ratio: 16 / 8.4;
}

@media (min-width: 60em) {
  .outro-video-2 {
    width: 31.6%;
    top: 5%;
    left: 59%;
  }
}

.outro-subtitle {
  max-width: 18.75rem;
  margin: clamp(1rem, 4vw, 2.3rem) 0 10rem;
}

.outro-case {
  text-decoration: none;
  display: block;
}

.outro-cases {
  grid-gap: 2.4rem 2.4rem;
  max-width: 25rem;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.4rem;
  display: grid;
}

@media (min-width: 60em) {
  .outro-cases {
    grid-gap: 1.25rem 1.25rem;
    max-width: 70rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.25rem;
    display: grid;
  }
}

.outro-case:hover .outro-case-play {
  transform: scale(1.1);
}

.outro-case-title {
  margin-bottom: clamp(1rem, 3vw, 1.25rem);
}

.outro-case-thumbnail {
  position: relative;
}

.outro-case-thumbnail:before {
  content: "";
  width: 12rem;
  height: 12rem;
  z-index: 2;
  background: radial-gradient(#0006 0%, #0000 70%);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: calc(50% - 6rem);
  left: calc(50% - 6rem);
}

.outro-case-image {
  border-radius: .625rem;
}

.outro-case-play {
  width: 2.6rem;
  z-index: 3;
  transition: transform .2s;
  position: absolute;
  top: calc(50% - 1.3rem);
  left: calc(50% - 1.3rem);
}

.stop-scroll {
  height: 100%;
  overflow: hidden;
}

.mediabox-wrap {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
  background-color: #000c;
  animation-name: mediabox;
  animation-duration: .5s;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes mediabox {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.mediabox-content {
  max-width: 853px;
  height: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.mediabox-content iframe {
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  max-width: 100% !important;
  width: 100% !important;
  height: 480px !important;
  border: none !important;
  display: block !important;
}

.mediabox-hide {
  animation-name: mediaboxhide;
  animation-duration: .5s;
  animation-fill-mode: both;
}

@keyframes mediaboxhide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.mediabox-close {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAMvSURBVHja7Js9aBRBFMd/d1lPY6FiJVjY+Fkoxl7wA1Q0prQRS6tgoZV2MWIRRVHUUq3U+JnESrS2sBXBzipREWMlATXwt8gFznC5nd15M7Nn8uC45nZnfr/dY96+N1uTxFKOOks8lgUU/H2t4tJqIQUcAiaBGeBymcECRgO4B/wBPgJ9zkdKcvkclfRL/8ZtSTXH40N+GpLGF8zth6Q9Lse7DHCsDXxVJLSDLyQhb4B+Sb/VOVJJ6ATfKqGvrIDjDvCpJLjAz8d0JwmLDTBQAD62hIakiYJzm5a021VAfwn4WBLKwLdK2JUnIJP0XX4RSoIP/Hy8W3jeepv1dL3nmjwI3DLOExrAU2DA8zwb8xKhGeCuwYQtJTSAZwbwAHdcEqFM0mPZhO/foSHppdFcrraby2IDV0FCcPi8PCClhCjwLplgCgkrDeGv5I3pcjViSogK7yogloTo8EUEhJaQBL6oAGsJ9yVtkrRD0qsU8JKolagKZ8AD4ETFymFXgPOAQpXE5mMWOAk86XZ4n6pwlSSUhvcti1dBghe8RV8gpYQRX3irxkgKCSPABV94y85QTAlm8NatsRgSTOGBUnmAS57w3KiA0Ro3gHOW8KEEAOwE3hvfXWubFauu6A6vCND07OmW9viq5vpsGT3AtRAN2XoA+BfAwQBiTweoNpMZw48BRwKuAoPN7zNVWwZjwAfpO9S7DN5cQmYAPw4cTvAsYPJ3qHcpvNmdUO9ieBMJZQT0AhMVgfeWUC8BP87cjjHfuA6sATY0c4c0EgpUUHslvTaq3l5aUL1N1oarAnxSCVWBTyYhJvyw41XJJI3GkpAH/yYyfHQJi01gdUL4qBKqCh9NQrtBx4wGvGi0XS6T9MhoTkN5AtZVDN5awlTePsGfwDfPjGwYGDKu3s4Cp4BRz/N8cskED0iaqciVt7wTvkra5roKlJEQGt5HwhdJ24vmAUUkDEV+VyCT9NBxbp/bXXnXTNBFQmz4IhI6wrs+C+zvICEVvIuEKUlbrZ4G97WRkBq+k4RJSVusd4ntlfSheVudrQh8q4SbmntH6K2kzSF3if1Xsfzq7LKAJR5/BwCdAQBJn4egPgAAAABJRU5ErkJggg==") 0 0 / 24px 24px no-repeat;
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 528px;
  right: 0;
}

.mediabox-close:hover {
  opacity: .5;
}

@media (max-width: 768px) {
  .mediabox-content {
    max-width: 90%;
  }
}

@media (max-width: 600px) {
  .mediabox-content iframe {
    height: 320px !important;
  }

  .mediabox-close {
    bottom: 362px;
  }
}

@media (max-width: 480px) {
  .mediabox-content iframe {
    height: 220px !important;
  }

  .mediabox-close {
    bottom: 262px;
  }
}

/*# sourceMappingURL=index.c7e915c3.css.map */
